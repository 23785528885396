import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { HorarioDisponibleDiaModel, HorarioDisponibleHoraModel, HorarioDisponibleModel } from '../../../types/configuracion';
import { getDisponibilidadHorario, getTipoPago, postCita } from 'api/calls';
import { useEffect, useState } from 'react';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MainCard from 'ui-component/cards/MainCard';
import { TipoPagoCatalogo } from '../../../types/catalogo';
import { format } from 'date-fns';
import useAuth from 'hooks/useAuth';
import { useLayout } from '../../../contexts/LayoutContext';
import { useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

export interface CitaFormulario {
    tipoPago: string;
}

export interface CitaFormularioError {
    tipoPago: boolean;
}

const AgendarCitaHorarios = () => {
    const { data } = useAuth();
    const { idDoctor, idPregunta } = useParams();

    const { handleOpenBackdrop, handleCloseBackdrop } = useLayout();
    const theme = useTheme();

    const [horarioDisponibilidadObj, setHorarioDisponibilidadObj] = useState<HorarioDisponibleModel | null>(null);
    const [catalogoTipoPago, setCatalogoTipoPago] = useState<TipoPagoCatalogo[]>([]);
    const [citaFormulario, setCitaFormulario] = useState<CitaFormulario>({
        tipoPago: ''
    });
    const [formularioError, setFormularioError] = useState<CitaFormularioError>({
        tipoPago: false
    });
    const [diaSeleccionado, setDiaSeleccionado] = useState<HorarioDisponibleDiaModel | null>(null);
    const [horaSeleccionada, setHoraSeleccionada] = useState<HorarioDisponibleHoraModel | null>(null);
    const [openAgendarCita, setOpenAgendarCita] = useState(false);

    const handleCloseAgendarCita = () => setOpenAgendarCita(false);

    const handleClickAgendarCita = (dia: HorarioDisponibleDiaModel, hora: HorarioDisponibleHoraModel) => {
        setDiaSeleccionado(dia);
        setHoraSeleccionada(hora);
        setCitaFormulario({
            tipoPago: ''
        });
        setOpenAgendarCita(true);
    };

    const handleChangeFormulario = (e: any) => {
        setCitaFormulario({
            ...citaFormulario,
            [e.target.name]: e.target.value
        });
    };

    const fechaActual = new Date();

    const handleClickObtenerDisponibilidad = (fecha: string = '') => {
        handleOpenBackdrop();
        getDisponibilidadHorario(idDoctor as string, fecha).then((response) => {
            handleCloseBackdrop();
            if (!response.hasError) {
                setHorarioDisponibilidadObj(response.data);
            }
        });
    };

    const handleClickObtenerTipoPago = () => {
        getTipoPago().then((response) => {
            if (!response.hasError) {
                setCatalogoTipoPago(response.data as TipoPagoCatalogo[]);
            }
        });
    };

    const handleClickNavegarSemana = (value: string) => {
        handleClickObtenerDisponibilidad(value);
    };

    const handleClickConfirmarCita = () => {
        const errores: CitaFormularioError = {
            tipoPago: !citaFormulario.tipoPago
        };

        setFormularioError(errores);

        if (Object.values(errores).includes(true)) {
            return;
        }

        handleOpenBackdrop();
        postCita(data?.token as string, {
            tipoPago: parseInt(citaFormulario.tipoPago),
            idCentroMedico: horaSeleccionada?.idCentroMedico as string,
            fechaInicio: format(
                new Date(
                    diaSeleccionado?.year as number,
                    (diaSeleccionado?.mes as number) - 1,
                    diaSeleccionado?.dia as number,
                    horaSeleccionada?.hora as number,
                    0,
                    0,
                    0
                ),
                "yyyy-MM-dd'T'HH:mm:ss"
            ),
            fechaFin: format(
                new Date(
                    diaSeleccionado?.year as number,
                    (diaSeleccionado?.mes as number) - 1,
                    diaSeleccionado?.dia as number,
                    (horaSeleccionada?.hora as number) + 1,
                    0,
                    0,
                    0
                ),
                "yyyy-MM-dd'T'HH:mm:ss"
            ),
            idDoctor: idDoctor as string,
            idPregunta: !idPregunta ? null : (idPregunta as string)
        }).then((response) => {
            handleCloseBackdrop();
            if (!response.hasError) {
                setOpenAgendarCita(false);
                window.location.replace(response.data as string);
            }
        });
    };

    useEffect(() => {
        handleClickObtenerDisponibilidad();
        handleClickObtenerTipoPago();
        //eslint-disable-next-line
    }, []);

    if (!horarioDisponibilidadObj) {
        return null;
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MainCard title="Horarios disponibles">
                        <TableContainer>
                            <Table padding="checkbox">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ width: 100 }}>
                                            <Tooltip title="Semana anterior">
                                                <IconButton
                                                    onClick={() => handleClickNavegarSemana(horarioDisponibilidadObj.semanaAnterior)}
                                                >
                                                    <ArrowBackIosIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Siguiente semana">
                                                <IconButton
                                                    onClick={() => handleClickNavegarSemana(horarioDisponibilidadObj.semanaSiguiente)}
                                                >
                                                    <ArrowForwardIosIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        {horarioDisponibilidadObj.dias.map((x) => (
                                            <TableCell
                                                key={x.diaSemana}
                                                align="center"
                                                style={{
                                                    border:
                                                        fechaActual.getDate() === x.dia
                                                            ? '2px solid ' + theme.palette.secondary.main
                                                            : 'initial'
                                                }}
                                            >
                                                <Typography variant="h6">{x.nombreMes}</Typography>
                                                <Typography>{x.nombreDia}</Typography>
                                                <Typography variant="h2">{x.dia}</Typography>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {horarioDisponibilidadObj.horas.map((hora) => (
                                        <TableRow key={hora}>
                                            <TableCell align="center">
                                                {hora}:00 - {hora + 1}:00
                                            </TableCell>
                                            {horarioDisponibilidadObj.dias.map((dia) => (
                                                <TableCell
                                                    key={dia.diaSemana}
                                                    sx={{
                                                        backgroundColor:
                                                            fechaActual.getDate() === dia.dia ? theme.palette.primary.light : 'initial'
                                                    }}
                                                >
                                                    {dia.horas
                                                        .filter((x) => x.hora === hora)
                                                        .map((horario) => {
                                                            const fechaHorario = new Date(dia.year, dia.mes - 1, dia.dia, hora, 0, 0, 0);

                                                            if (!!horario.idCita && fechaActual < fechaHorario) {
                                                                return (
                                                                    <Typography color="error" textAlign="center">
                                                                        Ocupado
                                                                    </Typography>
                                                                );
                                                            }

                                                            if (horario.disponible && fechaActual < fechaHorario) {
                                                                return (
                                                                    <Button
                                                                        id={fechaHorario.toISOString()}
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        sx={{ flexDirection: 'column' }}
                                                                        onClick={() => handleClickAgendarCita(dia, horario)}
                                                                    >
                                                                        <Typography>Horario disponible</Typography>
                                                                        <Typography variant="caption" color="inherit">
                                                                            {horario.nombreCentroMedico}
                                                                        </Typography>
                                                                    </Button>
                                                                );
                                                            }

                                                            return <Typography textAlign="center">No disponible</Typography>;
                                                        })}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </MainCard>
                </Grid>
            </Grid>
            <Dialog open={openAgendarCita} onClose={handleCloseAgendarCita} fullWidth maxWidth="sm">
                {diaSeleccionado && horaSeleccionada && (
                    <>
                        <DialogTitle>Agendar cita</DialogTitle>
                        <DialogContent>
                            <Box p={1}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5">
                                            {diaSeleccionado.nombreDia} {diaSeleccionado.dia} de {diaSeleccionado.nombreMes}
                                        </Typography>
                                        <Typography gutterBottom>
                                            {horaSeleccionada.hora}:00 - {horaSeleccionada.hora + 1}:00
                                        </Typography>
                                        <Typography variant="h6">Centro médico:</Typography>
                                        <Typography>{horaSeleccionada.nombreCentroMedico}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="tipoPago"
                                            fullWidth
                                            label="Tipo de pago:"
                                            value={citaFormulario.tipoPago}
                                            onChange={handleChangeFormulario}
                                            error={formularioError.tipoPago}
                                            required
                                            select
                                        >
                                            <MenuItem value="">-- Seleccione tipo de pago --</MenuItem>
                                            {catalogoTipoPago.map((x) => (
                                                <MenuItem key={x.idTipoPago} value={x.idTipoPago}>
                                                    {x.descripcionTipoPago}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="inherit" onClick={handleCloseAgendarCita}>
                                Cerrar
                            </Button>
                            <Button variant="contained" color="secondary" onClick={handleClickConfirmarCita}>
                                Confirmar
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default AgendarCitaHorarios;
