// types
import { NavItemType } from 'types';
// project import
// import other from './other';
import pages from './pages';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    // items: [pages, other]
    items: [...pages]
};

export default menuItems;
