import AgendarCita from 'views/pages/citas/AgendarCita';
import AgendarCitaHorarios from '../views/pages/citas/AgendarCitaHorarios';
// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import ChatPregunta from 'views/pages/perfilamiento/ChatPregunta';
import CitaResumenCompra from '../views/pages/citas/CitaResumenCompra';
import CitasAgendadas from '../views/pages/citas/CitasAgendadas';
import Loadable from 'ui-component/Loadable';
import MainLayout from 'layout/MainLayout';
// import Precios from 'views/pages/configuracion/Precios';
import Preguntas from 'views/pages/perfilamiento/Preguntas';
import { lazy } from 'react';
import Perfil from 'views/pages/configuracion/Perfil';
import Citas from 'views/pages/citas/Citas';

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/perfilamiento/preguntas',
            element: <Preguntas />
        },
        {
            path: '/perfilamiento/preguntas/:idPregunta/chat',
            element: <ChatPregunta />
        },
        {
            path: '/citas',
            element: <Citas />
        },
        {
            path: '/agendar-citar',
            element: <AgendarCita />
        },
        {
            path: '/agendar-citar/:idPregunta',
            element: <AgendarCita />
        },
        {
            path: '/agendar-citar/doctor/:idDoctor/horarios-disponibles',
            element: <AgendarCitaHorarios />
        },
        {
            path: '/agendar-citar/:idPregunta/doctor/:idDoctor/horarios-disponibles',
            element: <AgendarCitaHorarios />
        },
        {
            path: '/agendar-citar/:sessionId/resumen-compra',
            element: <CitaResumenCompra />
        },
        {
            path: '/citas/programadas',
            element: <CitasAgendadas />
        },
        {
            path: '/configuracion/perfil',
            element: <Perfil />
        }
        // {
        //     path: '/citas/agendar',
        //     element: <AgendarCita />
        // },
        // {
        //     path: '/citas/programadas',
        //     element: <CitasAgendadas />
        // },
        // {
        //     path: '/citas/directorio-medico',
        //     element: <DirectorioMedico />
        // },
        // {
        //     path: '/administracion/centros-medicos',
        //     element: <CentrosMedicos />
        // },
        // {
        //     path: '/administracion/centros-medicos/agregar',
        //     element: <CentroMedicoFormulario />
        // },
        // {
        //     path: '/administracion/centros-medicos/:idCentroMedico/editar',
        //     element: <CentroMedicoFormulario />
        // },
        // {
        //     path: '/administracion/doctores',
        //     element: <Doctores />
        // },
        // {
        //     path: '/administracion/doctores/agregar',
        //     element: <DoctoresFormulario />
        // },
        // {
        //     path: '/administracion/doctores/:idDoctor/editar',
        //     element: <DoctoresFormulario />
        // },
        // {
        //     path: '/administracion/doctores/:idDoctor/agendar',
        //     element: <HorariosDisponibles />
        // },
        // {
        //     path: '/configuracion/horarios',
        //     element: <Horario />
        // },
        // {
        //     path: '/configuracion/precios',
        //     element: <Precios />
        // }
    ]
};

export default MainRoutes;
