// project imports
import { DASHBOARD_PATH } from 'config';
// material-ui
import { Link } from '@mui/material';
import Logo from 'ui-component/Logo';
import LogoHeyDoctor from '../../../assets/images/logos.png';
import { Link as RouterLink } from 'react-router-dom';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Link component={RouterLink} to={DASHBOARD_PATH}>
        <img src={LogoHeyDoctor} alt="" style={{ height: 40 }} />
        {/* <Logo /> */}
    </Link>
);

export default LogoSection;
