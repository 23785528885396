import { Alert, Box, Button, Chip, Drawer, Grid, IconButton, Link as LinkMui, Stack, TableCell, Tooltip, Typography } from '@mui/material';
import { IconListDetails } from '@tabler/icons';
import { getCitasPaciente } from 'api/calls';
import { useLayout } from 'contexts/LayoutContext';
import { format } from 'date-fns';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CitaModel } from 'types/administracion';
import MainCard from 'ui-component/cards/MainCard';
import TableComponent from 'ui-component/TableComponent';
import CircleIcon from '@mui/icons-material/Circle';

function getColorEstatusCita(idEstusCita: number): any {
    switch (idEstusCita) {
        case 1:
            return 'info';
        case 2:
            return 'warning';
        case 3:
            return 'info';
        case 4:
            return 'info';
        case 5:
            return 'success';
        case 6:
            return 'error';
        case 7:
            return 'error';

        default:
            return 'inherit';
    }
}

const Citas = () => {
    const { data } = useAuth();

    const { handleOpenBackdrop, handleCloseBackdrop } = useLayout();

    const [listaCitas, setListaCitas] = useState<CitaModel[]>([]);
    const [citaSeleccionada, setCitaSeleccionada] = useState<CitaModel | null>(null);
    const [openCitaDetalle, setOpenCitaDetalle] = useState(false);

    const handleCloseCitaDetalle = () => setOpenCitaDetalle(false);

    const handleClickDetalleCita = (cita: CitaModel) => {
        setCitaSeleccionada(cita);
        setOpenCitaDetalle(true);
    };

    const handleClickObtenerCitas = () => {
        handleOpenBackdrop();
        getCitasPaciente(data?.token as string).then((response) => {
            handleCloseBackdrop();
            if (!response.hasError) {
                setListaCitas(response.data as CitaModel[]);
                const params = new URLSearchParams(window.location.search);
                // const params = new URL(window.location).searchParams;
                const idCita = params.get('idCita');
                if (idCita) {
                    const citaDetalle = (response.data as CitaModel[]).find((x) => x.idCita === idCita);
                    if (citaDetalle) {
                        setCitaSeleccionada(citaDetalle);
                        setOpenCitaDetalle(true);
                    }
                }
            }
        });
    };

    useEffect(() => {
        handleClickObtenerCitas();
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="h4">Todas mis citas</Typography>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                <Link to="/agendar-citar" style={{ textDecoration: 'none' }}>
                                    <Button disableRipple color="secondary" variant="contained">
                                        Agendar una cita
                                    </Button>
                                </Link>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <MainCard>
                        <TableComponent
                            dataId="idCita"
                            dataList={listaCitas}
                            defaultOrder=""
                            showActionCell
                            headCells={[
                                {
                                    id: 'numeroOrdenCita',
                                    numeric: false,
                                    label: 'Número de cita',
                                    align: 'left'
                                },
                                {
                                    id: 'fechaInicio',
                                    numeric: false,
                                    label: 'Fecha',
                                    align: 'left'
                                },
                                {
                                    id: 'nombreEspecialidad',
                                    numeric: false,
                                    label: 'Especialidad',
                                    align: 'left'
                                },
                                {
                                    id: 'nombresDoctor',
                                    numeric: false,
                                    label: 'Doctor',
                                    align: 'left'
                                },
                                {
                                    id: 'descripcionEstatusCita',
                                    numeric: false,
                                    label: 'Estatus',
                                    align: 'left'
                                }
                            ]}
                            rowRender={(row) => (
                                <>
                                    <TableCell>{row.numeroOrdenCita}</TableCell>
                                    <TableCell>{format(new Date(row.fechaInicio), 'dd/MM/yyyy hh:mm aaa')}</TableCell>
                                    <TableCell>{row.nombreEspecialidad}</TableCell>
                                    <TableCell>{row.nombresDoctor}</TableCell>
                                    <TableCell>
                                        <Stack flexDirection="row" alignItems="center">
                                            <IconButton color={getColorEstatusCita(row.idEstatusCita)}>
                                                <CircleIcon />
                                            </IconButton>
                                            <Typography>{row.descripcionEstatusCita}</Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title="Ver detalles de cita">
                                            <IconButton color="primary" onClick={() => handleClickDetalleCita(row)}>
                                                <IconListDetails />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </>
                            )}
                            searchProperties={[
                                'numeroOrdenCita',
                                'fechaInicio',
                                'nombreEspecialidad',
                                'nombresDoctor',
                                'descripcionEstatusCita'
                            ]}
                            tableTitle="Lista de citas"
                        />
                    </MainCard>
                </Grid>
            </Grid>
            <Drawer open={openCitaDetalle} anchor="right" onClose={handleCloseCitaDetalle}>
                <Box width={650} p={2}>
                    {citaSeleccionada && (
                        <Grid container spacing={2}>
                            {citaSeleccionada.idEstatusCita === 2 && (
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        <Typography>Completa el pago de tu cita para confirmarle al médico tu asistencia</Typography>
                                        <br />
                                        <LinkMui href={citaSeleccionada.paymentUrl} style={{ textDecoration: 'none' }}>
                                            <Button disableRipple color="secondary" variant="contained">
                                                Completa tu pago con {citaSeleccionada.descripcionTipoPago}
                                            </Button>
                                        </LinkMui>
                                    </Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Typography variant="h5">Número de cita: {citaSeleccionada.numeroOrdenCita}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h2">{format(new Date(citaSeleccionada.fechaInicio), 'dd/MM/yyyy')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h1">
                                    {format(new Date(citaSeleccionada.fechaInicio), 'h:mm aaa')} -{' '}
                                    {format(new Date(citaSeleccionada.fechaFin), 'h:mm aaa')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Chip
                                    label={citaSeleccionada.descripcionEstatusCita}
                                    color={
                                        citaSeleccionada.idEstatusCita === 1
                                            ? 'info'
                                            : citaSeleccionada.idEstatusCita === 2
                                            ? 'warning'
                                            : citaSeleccionada.idEstatusCita === 3
                                            ? 'info'
                                            : citaSeleccionada.idEstatusCita === 4
                                            ? 'info'
                                            : citaSeleccionada.idEstatusCita === 5
                                            ? 'success'
                                            : citaSeleccionada.idEstatusCita === 6
                                            ? 'error'
                                            : citaSeleccionada.idEstatusCita === 7
                                            ? 'error'
                                            : 'default'
                                    }
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="caption">Hospital:</Typography>
                                <Typography>{citaSeleccionada.nombreCentroMedico}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">Dirección:</Typography>
                                <Typography>{citaSeleccionada.direccionCentroMedico}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">Doctor:</Typography>
                                <Typography>{citaSeleccionada.nombresDoctor}</Typography>
                                <Typography variant="subtitle2">{citaSeleccionada.nombreEspecialidad}</Typography>
                                <Typography variant="subtitle2">No. de célula {citaSeleccionada.cedulaProfesional}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">Total de la cita:</Typography>
                                <Typography variant="h4">${citaSeleccionada.totalPagoCita}</Typography>
                                <Typography variant="body1">Pago por {citaSeleccionada.descripcionTipoPago}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">Pregunta relacionada:</Typography>
                                <Typography>
                                    {citaSeleccionada.pregunta ? citaSeleccionada.pregunta : '(No hay pregunta relacionada)'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">Ubicación de mi cita:</Typography>
                            </Grid>
                            <Grid item xs={12} dangerouslySetInnerHTML={{ __html: citaSeleccionada.ubicacionMaps }}></Grid>
                        </Grid>
                    )}
                </Box>
            </Drawer>
        </>
    );
};

export default Citas;
