import { Alert, Box, Button, Chip, Drawer, Grid, Link as LinkMui, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import FullCalendar, { EventClickArg } from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import { useEffect, useRef, useState } from 'react';

import CalendarStyled from 'ui-component/CalendarStyled';
import { CitaModel } from '../../../types/administracion';
import { IconAlarm } from '@tabler/icons';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import Toolbar from 'ui-component/Toolbar';
import dayGridPlugin from '@fullcalendar/daygrid';
import { format } from 'date-fns';
import { getCitasPaciente } from 'api/calls';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';
import useAuth from 'hooks/useAuth';
import { useLayout } from 'contexts/LayoutContext';

// third-party

const CitasAgendadas = () => {
    const calendarRef = useRef<FullCalendar>(null);
    const theme = useTheme();
    const matchSm = useMediaQuery((themem: Theme) => themem.breakpoints.down('md'));
    const { data } = useAuth();

    const { handleOpenBackdrop, handleCloseBackdrop } = useLayout();

    const [date, setDate] = useState(new Date());
    const [view, setView] = useState('listWeek');
    const [listaCitas, setListaCitas] = useState<CitaModel[]>([]);
    const [citaSeleccionada, setCitaSeleccionada] = useState<CitaModel | null>(null);
    const [openCitaDetalle, setOpenCitaDetalle] = useState(false);

    const handleCloseCitaDetalle = () => setOpenCitaDetalle(false);

    const handleClickObtenerCitas = () => {
        handleOpenBackdrop();
        getCitasPaciente(data?.token as string).then((response) => {
            handleCloseBackdrop();
            if (!response.hasError) {
                setListaCitas(response.data as CitaModel[]);
            }
        });
    };

    const handleDateNext = () => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.next();
            setDate(calendarApi.getDate());
        }
    };

    const handleDatePrev = () => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.prev();
            setDate(calendarApi.getDate());
        }
    };

    const handleDateToday = () => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.today();
            setDate(calendarApi.getDate());
        }
    };

    const handleViewChange = (newView: string) => {
        const calendarEl = calendarRef.current;

        if (calendarEl) {
            const calendarApi = calendarEl.getApi();

            calendarApi.changeView(newView);
            setView(newView);
        }
    };

    const handleEventSelect = (arg: EventClickArg) => {
        if (arg.event.id) {
            const citaModel = listaCitas.find((x) => x.idCita === arg.event.id);
            setCitaSeleccionada(citaModel as CitaModel);
            setOpenCitaDetalle(true);
        }
    };

    useEffect(() => {
        handleViewChange('listWeek');
    }, [matchSm]);

    useEffect(() => {
        handleClickObtenerCitas();
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MainCard
                        title="Citas programadas"
                        secondary={
                            <Button color="secondary" variant="contained">
                                <IconAlarm fontSize="small" />
                                Citas
                            </Button>
                        }
                    >
                        <CalendarStyled>
                            <Toolbar
                                date={date}
                                view={view}
                                onClickNext={handleDateNext}
                                onClickPrev={handleDatePrev}
                                onClickToday={handleDateToday}
                                onChangeView={handleViewChange}
                            />
                            <SubCard>
                                <FullCalendar
                                    weekends
                                    editable
                                    droppable
                                    selectable
                                    events={listaCitas.map((x) => ({
                                        id: x.idCita,
                                        allDay: false,
                                        color:
                                            x.idEstatusCita === 1
                                                ? theme.palette.info.main
                                                : x.idEstatusCita === 2
                                                ? theme.palette.warning.dark
                                                : x.idEstatusCita === 3
                                                ? theme.palette.info.main
                                                : x.idEstatusCita === 4
                                                ? theme.palette.info.main
                                                : x.idEstatusCita === 5
                                                ? theme.palette.success.main
                                                : x.idEstatusCita === 6
                                                ? theme.palette.error.main
                                                : x.idEstatusCita === 7
                                                ? theme.palette.error.main
                                                : theme.palette.grey[300],
                                        title: `${x.numeroOrdenCita} - ${x.nombresDoctor} (${x.nombreHospital})`,
                                        start: new Date(x.fechaInicio),
                                        end: new Date(x.fechaFin),
                                        editable: false,
                                        display: 'block',
                                        overlap: true
                                    }))}
                                    ref={calendarRef}
                                    rerenderDelay={10}
                                    initialDate={date}
                                    initialView={view}
                                    eventDisplay="block"
                                    // contentHeight="100hv"
                                    displayEventEnd
                                    displayEventTime
                                    height={1000}
                                    headerToolbar={false}
                                    eventResizableFromStart
                                    // select={handleRangeSelect}
                                    eventDrop={() => {}}
                                    eventClick={handleEventSelect}
                                    // eventResize={handleEventUpdate}
                                    // height={matchSm ? 'auto' : 720}
                                    plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
                                    views={{
                                        dayGridPlugin: {
                                            eventMinHeight: 300
                                        }
                                    }}
                                />
                            </SubCard>
                        </CalendarStyled>
                    </MainCard>
                </Grid>
            </Grid>
            <Drawer open={openCitaDetalle} anchor="right" onClose={handleCloseCitaDetalle}>
                <Box width={650} p={2}>
                    {citaSeleccionada && (
                        <Grid container spacing={2}>
                            {citaSeleccionada.idEstatusCita === 2 && (
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        <Typography>Completa el pago de tu cita para confirmarle al médico tu asistencia</Typography>
                                        <br />
                                        <LinkMui href={citaSeleccionada.paymentUrl} style={{ textDecoration: 'none' }}>
                                            <Button disableRipple color="secondary" variant="contained">
                                                Completa tu pago con {citaSeleccionada.descripcionTipoPago}
                                            </Button>
                                        </LinkMui>
                                    </Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Typography variant="h5">Número de cita: {citaSeleccionada.numeroOrdenCita}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h2">{format(new Date(citaSeleccionada.fechaInicio), 'dd/MM/yyyy')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h1">
                                    {format(new Date(citaSeleccionada.fechaInicio), 'h:mm aaa')} -{' '}
                                    {format(new Date(citaSeleccionada.fechaFin), 'h:mm aaa')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Chip
                                    label={citaSeleccionada.descripcionEstatusCita}
                                    color={
                                        citaSeleccionada.idEstatusCita === 1
                                            ? 'info'
                                            : citaSeleccionada.idEstatusCita === 2
                                            ? 'warning'
                                            : citaSeleccionada.idEstatusCita === 3
                                            ? 'info'
                                            : citaSeleccionada.idEstatusCita === 4
                                            ? 'info'
                                            : citaSeleccionada.idEstatusCita === 5
                                            ? 'success'
                                            : citaSeleccionada.idEstatusCita === 6
                                            ? 'error'
                                            : citaSeleccionada.idEstatusCita === 7
                                            ? 'error'
                                            : 'default'
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">Hospital:</Typography>
                                <Typography>{citaSeleccionada.nombreCentroMedico}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">Dirección:</Typography>
                                <Typography>{citaSeleccionada.direccionCentroMedico}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">Doctor:</Typography>
                                <Typography>{citaSeleccionada.nombresDoctor}</Typography>
                                <Typography variant="subtitle2">{citaSeleccionada.nombreEspecialidad}</Typography>
                                <Typography variant="subtitle2">No. de célula {citaSeleccionada.cedulaProfesional}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">Total de la cita:</Typography>
                                <Typography variant="h4">${citaSeleccionada.totalPagoCita}</Typography>
                                <Typography variant="body1">Pago por {citaSeleccionada.descripcionTipoPago}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">Pregunta relacionada:</Typography>
                                <Typography>
                                    {citaSeleccionada.pregunta ? citaSeleccionada.pregunta : '(No hay pregunta relacionada)'}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption">Ubicación de mi cita:</Typography>
                            </Grid>
                            <Grid item xs={12} dangerouslySetInnerHTML={{ __html: citaSeleccionada.ubicacionMaps }}></Grid>
                        </Grid>
                    )}
                </Box>
            </Drawer>
        </>
    );
};

export default CitasAgendadas;
