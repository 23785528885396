// assets
import { IconCalendarPlus, IconHomeQuestion, IconKey, IconReportMedical } from '@tabler/icons';

// third-party
// import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //
const pages: NavItemType[] = [
    {
        id: 'perfilamiento',
        title: <>Perfilamiento</>,
        // caption: <FormattedMessage id="pages-caption" />,
        icon: IconKey,
        type: 'group',
        children: [
            {
                id: 'preguntas',
                title: <>Mis preguntas</>,
                type: 'item',
                icon: IconHomeQuestion,
                url: '/perfilamiento/preguntas',
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'citas',
        title: <>Citas</>,
        // caption: <FormattedMessage id="pages-caption" />,
        icon: IconKey,
        type: 'group',
        children: [
            {
                id: 'miscitas',
                title: <>Mis citas</>,
                type: 'item',
                icon: IconReportMedical,
                url: '/citas',
                breadcrumbs: false
            },
            {
                id: 'citasprogramadas',
                title: <>Mi agenda</>,
                type: 'item',
                icon: IconCalendarPlus,
                url: '/citas/programadas',
                breadcrumbs: false
            }
        ]
    }
    // {
    //     id: 'citas',
    //     title: <>Citas</>,
    //     // caption: <FormattedMessage id="pages-caption" />,
    //     icon: IconKey,
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'agendarcita',
    //             title: <>Agendar una cita</>,
    //             type: 'item',
    //             icon: IconStethoscope,
    //             url: '/citas/agendar',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'directoriomedico',
    //             title: <>Directorio médico</>,
    //             type: 'item',
    //             icon: IconAddressBook,
    //             url: '/citas/directorio-medico',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'citasprogramadas',
    //             title: <>Citas programadas</>,
    //             type: 'item',
    //             icon: IconCalendarPlus,
    //             url: '/citas/programadas',
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    // {
    //     id: 'administracion',
    //     title: <>Administración</>,
    //     icon: IconKey,
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'centroMedico',
    //             title: <>Centros médicos</>,
    //             type: 'item',
    //             icon: IconBuildingHospital,
    //             url: '/administracion/centros-medicos',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'doctores',
    //             title: <>Doctores</>,
    //             type: 'item',
    //             icon: IconUser,
    //             url: '/administracion/doctores',
    //             breadcrumbs: false
    //         }
    //     ]
    // },
    // {
    //     id: 'configuracion',
    //     title: <>Configuración</>,
    //     icon: IconKey,
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'horario',
    //             title: <>Horarios</>,
    //             type: 'item',
    //             icon: IconCalendarTime,
    //             url: '/configuracion/horarios',
    //             breadcrumbs: false
    //         },
    //         {
    //             id: 'precios',
    //             title: <>Precios</>,
    //             type: 'item',
    //             icon: IconTags,
    //             url: '/configuracion/precios',
    //             breadcrumbs: false
    //         }
    //     ]
    // }
];

export default pages;
