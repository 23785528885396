import React, { useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project imports
// import useAuth from 'hooks/useAuth';
import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { strengthColor, strengthIndicator } from 'utils/password-strength';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { StringColorProps } from 'types';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';

// ========================|| FIREBASE - RESET PASSWORD ||======================== //

const AuthResetPassword = ({ ...others }) => {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const [showPassword, setShowPassword] = React.useState(false);
    const [strength, setStrength] = React.useState(0);
    const [level, setLevel] = React.useState<StringColorProps>();
    const [email, setEmail] = React.useState('');
    const [codeResetPassword, setCodeResetPassword] = React.useState('');
    const [blockPage, setBlockPage] = React.useState(false);

    const { updatePassword, validateResetPasswordCode } = useAuth();
    const navigate = useNavigate();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.SyntheticEvent) => {
        event.preventDefault();
    };

    const changePassword = (value: string) => {
        const temp = strengthIndicator(value);
        setStrength(temp);
        setLevel(strengthColor(temp));
    };

    const verifyResetPasswordCode = async () => {
        const params = new URLSearchParams(window.location.search);
        // const params = new URL(window.location).searchParams;
        const code = params.get('oobCode');
        const emailResponse = await validateResetPasswordCode(code as string);
        if (!emailResponse) {
            setBlockPage(true);
            return;
        }
        setCodeResetPassword(code as string);
        setEmail(emailResponse);
    };

    const handleClickGoToLogin = () => {
        navigate('/login');
    };

    useEffect(() => {
        changePassword('');
        verifyResetPasswordCode();
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                    confirmPassword: '',
                    submit: null
                }}
                validationSchema={Yup.object().shape({
                    password: Yup.string().max(255).required('La contraseña es requerida'),
                    confirmPassword: Yup.string().when('password', {
                        is: (val: string) => !!(val && val.length > 0),
                        then: Yup.string().oneOf([Yup.ref('password')], 'Las contraseñas no coinciden')
                    })
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        await updatePassword(codeResetPassword, email, values.password);
                        if (scriptedRef.current) {
                            setStatus({ success: true });
                            setSubmitting(false);
                        }
                    } catch (err: any) {
                        console.error(err);
                        if (scriptedRef.current) {
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit} {...others}>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.password && errors.password)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-password-reset">Nueva contraseña:</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-reset"
                                type={showPassword ? 'text' : 'password'}
                                value={values.password}
                                name="password"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    handleChange(e);
                                    changePassword(e.target.value);
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="large"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                inputProps={{}}
                            />
                        </FormControl>
                        {touched.password && errors.password && (
                            <FormControl fullWidth>
                                <FormHelperText error id="standard-weight-helper-text-reset">
                                    {errors.password}
                                </FormHelperText>
                            </FormControl>
                        )}
                        {strength !== 0 && (
                            <FormControl fullWidth>
                                <Box sx={{ mb: 2 }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <Box
                                                style={{ backgroundColor: level?.color }}
                                                sx={{
                                                    width: 85,
                                                    height: 8,
                                                    borderRadius: '7px'
                                                }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1" fontSize="0.75rem">
                                                {level?.label}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </FormControl>
                        )}

                        <FormControl
                            fullWidth
                            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                            sx={{ ...theme.typography.customInput }}
                        >
                            <InputLabel htmlFor="outlined-adornment-confirm-password">Confirmar contraseña:</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-confirm-password"
                                type="password"
                                value={values.confirmPassword}
                                name="confirmPassword"
                                label="Confirm Password"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                inputProps={{}}
                            />
                        </FormControl>

                        {touched.confirmPassword && errors.confirmPassword && (
                            <FormControl fullWidth>
                                <FormHelperText error id="standard-weight-helper-text-confirm-password">
                                    {' '}
                                    {errors.confirmPassword}{' '}
                                </FormHelperText>
                            </FormControl>
                        )}

                        {errors.submit && (
                            <Box
                                sx={{
                                    mt: 3
                                }}
                            >
                                <FormHelperText error>{errors.submit}</FormHelperText>
                            </Box>
                        )}
                        <Box
                            sx={{
                                mt: 1
                            }}
                        >
                            <AnimateButton>
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                >
                                    Aplicar cambio
                                </Button>
                            </AnimateButton>
                        </Box>
                    </form>
                )}
            </Formik>
            <Dialog open={blockPage} maxWidth="sm" fullWidth>
                <DialogTitle>Esta página ha expirado</DialogTitle>
                <DialogContent>Inicie sesión para continuar</DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleClickGoToLogin}>
                        Ir a Login
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default AuthResetPassword;
