import { Box, Drawer, Grid, IconButton, TableCell, Tooltip } from '@mui/material';
import { PreguntaModel } from 'types/pregunta';
import { IconFolder, IconHandStop, IconInbox, IconListDetails, IconMessage } from '@tabler/icons';
import { getPreguntaDetalle, getPreguntasPorPaciente } from 'api/calls';
import { useEffect, useState } from 'react';

import DetallePregunta from './DetallePregunta';
import { Link as LinkRouter } from 'react-router-dom';
import MainCard from '../../../ui-component/cards/MainCard';
import TableComponent from 'ui-component/TableComponent';
import useAuth from 'hooks/useAuth';
import { useLayout } from '../../../contexts/LayoutContext';
import { format } from 'date-fns';

const Preguntas = () => {
    const { data } = useAuth();
    const { handleOpenBackdrop, handleCloseBackdrop } = useLayout();

    const [preguntas, setPreguntas] = useState<PreguntaModel[]>([]);
    const [detallePregunta, setDetallePregunta] = useState<PreguntaModel | null>(null);

    const [modalOpenPreguntaDetalle, setModalOpenPreguntaDetalle] = useState(false);

    const handleClosePreguntaDetalle = () => setModalOpenPreguntaDetalle(false);

    const handleClickConsultarPreguntas = async () => {
        handleOpenBackdrop();
        const response = await getPreguntasPorPaciente(data?.token as string);
        handleCloseBackdrop();

        if (!response.hasError) {
            setPreguntas(response.data as PreguntaModel[]);
        }
    };

    const handleClickVerDetalle = async (pregunta: PreguntaModel) => {
        handleOpenBackdrop();
        const response = await getPreguntaDetalle(pregunta.idPregunta);
        handleCloseBackdrop();

        if (!response.hasError) {
            setDetallePregunta(response.data);
            setModalOpenPreguntaDetalle(true);
        }
    };

    useEffect(() => {
        handleClickConsultarPreguntas();
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <MainCard title="Lista de preguntas" content={false}>
                        <TableComponent
                            dataId="idPregunta"
                            dataList={preguntas}
                            defaultOrder=""
                            showActionCell
                            headCells={[
                                {
                                    id: 'cerrado',
                                    numeric: false,
                                    label: '',
                                    align: 'center'
                                },
                                {
                                    id: 'idPregunta',
                                    numeric: false,
                                    label: 'Folio',
                                    align: 'left'
                                },
                                {
                                    id: 'pregunta',
                                    numeric: false,
                                    label: 'Pregunta',
                                    align: 'left'
                                },
                                {
                                    id: 'nombreEstado',
                                    numeric: false,
                                    label: 'Estado',
                                    align: 'left'
                                },
                                {
                                    id: 'fechaRegistro',
                                    numeric: false,
                                    label: 'Registrado',
                                    align: 'left'
                                },
                                {
                                    id: 'nombresDoctor',
                                    numeric: false,
                                    label: 'Atiende',
                                    align: 'left'
                                }
                            ]}
                            rowRender={(row) => (
                                <>
                                    <TableCell>
                                        {row.cerrado ? (
                                            <Tooltip title="Cerrado/Archivado">
                                                <IconButton color="success">
                                                    <IconFolder />
                                                </IconButton>
                                            </Tooltip>
                                        ) : !!row.idDoctor ? (
                                            <Tooltip title="Atendiendo">
                                                <IconButton color="info">
                                                    <IconInbox />
                                                </IconButton>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Sin atender">
                                                <IconButton color="warning">
                                                    <IconHandStop />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                    <TableCell>{row.idPregunta.substring(0, 8).toUpperCase()}</TableCell>
                                    <TableCell>
                                        <div
                                            style={{
                                                overflowY: 'hidden',
                                                textOverflow: 'ellipsis',
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: 'vertical',
                                                display: '-webkit-box'
                                            }}
                                        >
                                            {row.pregunta}
                                        </div>
                                    </TableCell>
                                    <TableCell>{row.nombreEstado}</TableCell>
                                    <TableCell>{format(new Date(row.fechaRegistro + 'Z'), 'dd/MM/yyyy')}</TableCell>
                                    <TableCell>{(row.nombresDoctor || '') + ' ' + (row.apellidosDoctor || '')}</TableCell>
                                    <TableCell sx={{ minWidth: 120 }}>
                                        <Tooltip title="Ver detalles de pregunta">
                                            <IconButton onClick={() => handleClickVerDetalle(row)} color="primary">
                                                <IconListDetails />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Ir a chat">
                                            <LinkRouter to={`/perfilamiento/preguntas/${row.idPregunta}/chat`}>
                                                <IconButton>
                                                    <IconMessage />
                                                </IconButton>
                                            </LinkRouter>
                                        </Tooltip>
                                    </TableCell>
                                </>
                            )}
                            searchProperties={[
                                'idPregunta',
                                'correo',
                                'pregunta',
                                'nombreEstado',
                                'fechaRegistro',
                                'nombresDoctor',
                                'apellidosDoctor'
                            ]}
                            tableTitle="Lista de preguntas"
                        />
                    </MainCard>
                </Grid>
            </Grid>
            <Drawer open={modalOpenPreguntaDetalle} onClose={handleClosePreguntaDetalle} anchor="right">
                <Box p={3} width={500}>
                    {detallePregunta && <DetallePregunta detallePregunta={detallePregunta} />}
                </Box>
            </Drawer>
        </>
    );
};

export default Preguntas;
