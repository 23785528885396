import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import { LayoutProvider } from 'contexts/LayoutContext';
// project imports
import Locales from 'ui-component/Locales';
import { LocalizationProvider } from '@mui/x-date-pickers';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
// routing
import Routes from 'routes';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => (
    <ThemeCustomization>
        {/* RTL layout */}
        <RTLLayout>
            <Locales>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <NavigationScroll>
                        <AuthProvider>
                            <LayoutProvider>
                                <>
                                    <Routes />
                                    <Snackbar />
                                </>
                            </LayoutProvider>
                        </AuthProvider>
                    </NavigationScroll>
                </LocalizationProvider>
            </Locales>
        </RTLLayout>
    </ThemeCustomization>
);

export default App;
