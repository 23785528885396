import { Avatar, Box, Button, Card, Drawer, Grid, MenuItem, Stack, TextField, Typography, useTheme } from '@mui/material';
import { EspecialidadCatalogo, EstadoCatalogo } from 'types/catalogo';
import { getDirectorioMedico, getEspecialidad, getEstado, getMunicipios } from 'api/calls';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DirectorioMedicoFilterModel } from '../../../types/administracion';
import { DoctorModel } from 'types/administracion';
import { IconFilter } from '@tabler/icons';
import { MunicipioCatalogo } from '../../../types/catalogo';
import { useLayout } from '../../../contexts/LayoutContext';

const AgendarCita = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { idPregunta } = useParams();

    const params = new URLSearchParams(window.location.search);
    // const params = new URL(window.location).searchParams;
    const idEspecialidadQuery = params.get('idEspecialidad');

    const { handleOpenBackdrop, handleCloseBackdrop } = useLayout();

    const [listaDoctores, setListaDoctores] = useState<DoctorModel[]>([]);
    const [catalogoEstado, setCatalogoEstado] = useState<EstadoCatalogo[]>([]);
    const [catalogoMunicipio, setCatalogoMunicipio] = useState<MunicipioCatalogo[]>([]);
    const [catalogoEspecialidad, setCatalogoEspecialidad] = useState<EspecialidadCatalogo[]>([]);
    const [filtroDirectorio, setFiltroDirectorio] = useState<DirectorioMedicoFilterModel>({
        idEspecialidad: !idEspecialidadQuery ? '' : idEspecialidadQuery,
        idEstado: '',
        idMunicipio: ''
    });
    const [openFiltro, setOpenFiltro] = useState(false);

    const handleCloseFiltro = () => setOpenFiltro(false);

    const handleOpenFiltro = () => setOpenFiltro(true);

    const handleChangeFiltroDirectorio = (e: any) => {
        setFiltroDirectorio({
            ...filtroDirectorio,
            [e.target.name]: e.target.value
        });

        if (e.target.name === 'idEstado') {
            getMunicipios(e.target.value as string).then((response) => {
                if (!response.hasError) {
                    setCatalogoMunicipio(response.data as MunicipioCatalogo[]);
                } else {
                    setCatalogoMunicipio([]);
                }
            });
        }
    };

    const handleClickBorrarFiltros = () => {
        setFiltroDirectorio({
            idEspecialidad: '',
            idEstado: '',
            idMunicipio: ''
        });
    };

    const handleClickConsultarDirectorioMedico = () => {
        handleOpenBackdrop();
        getDirectorioMedico(filtroDirectorio).then((response) => {
            handleCloseBackdrop();
            if (!response.hasError) {
                setListaDoctores(response.data as DoctorModel[]);
            }
        });
    };

    const handleClickConsultarEstados = () => {
        getEstado().then((response) => {
            if (!response.hasError) {
                setCatalogoEstado(response.data as EstadoCatalogo[]);
            }
        });
    };

    const handleClickConsultaEspecialidades = () => {
        getEspecialidad().then((response) => {
            if (!response.hasError) {
                setCatalogoEspecialidad(response.data as EspecialidadCatalogo[]);
            }
        });
    };

    const handleClickSeleccionarDoctor = (idDoctor: string) => {
        const url = !idPregunta
            ? `/agendar-citar/doctor/${idDoctor}/horarios-disponibles`
            : `/agendar-citar/${idPregunta}/doctor/${idDoctor}/horarios-disponibles`;
        navigate(url);
    };

    useEffect(() => {
        handleClickConsultarDirectorioMedico();
        handleClickConsultarEstados();
        handleClickConsultaEspecialidades();
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="h4">Agenda tu cita con un especialista</Typography>
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                <Button
                                    disableRipple
                                    color="secondary"
                                    variant="contained"
                                    startIcon={<IconFilter />}
                                    onClick={handleOpenFiltro}
                                >
                                    Filtros
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                {listaDoctores.map((x) => (
                    <Grid key={x.idDoctor} item xs={12} md={6} lg={4} xl={3}>
                        <Card
                            sx={{
                                p: 2,
                                bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                                border: theme.palette.mode === 'dark' ? 'none' : '1px solid',
                                borderColor: theme.palette.grey[100],
                                height: '100%'
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 10 }}>
                                <div style={{ flex: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs zeroMinWidth style={{ cursor: 'pointer' }}>
                                            <Avatar alt={x.nombres} src={x.fotoPerfil} sx={{ width: 72, height: 72 }} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h3" component="div">
                                                {x.nombres}
                                            </Typography>
                                            <Typography variant="caption">{x.nombreEspecialidad}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption">Email</Typography>
                                            <Typography variant="h6">{x.correo}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption">Ubicación</Typography>
                                            <Typography variant="h6">
                                                {x.nombreMunicipio}, {x.nombreEstado}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                sx={{ width: '100%' }}
                                                onClick={() => handleClickSeleccionarDoctor(x.idDoctor)}
                                            >
                                                Agendar cita
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Drawer open={openFiltro} onClose={handleCloseFiltro} anchor="right">
                <Box p={2} width={500}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4">Filtrar directorio médico</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="idEspecialidad"
                                fullWidth
                                label="Especialidad:"
                                value={filtroDirectorio.idEspecialidad}
                                onChange={handleChangeFiltroDirectorio}
                                select
                            >
                                <MenuItem value="">-- Seleccione especialidad</MenuItem>
                                {catalogoEspecialidad.map((x) => (
                                    <MenuItem key={x.idEspecialidad} value={x.idEspecialidad}>
                                        {x.nombreEspecialidad}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="idEstado"
                                fullWidth
                                label="Estado:"
                                value={filtroDirectorio.idEstado}
                                onChange={handleChangeFiltroDirectorio}
                                select
                            >
                                <MenuItem value="">-- Seleccione estado</MenuItem>
                                {catalogoEstado.map((x) => (
                                    <MenuItem key={x.idEstado} value={x.idEstado}>
                                        {x.nombreEstado}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="idMunicipio"
                                fullWidth
                                label="Municipio:"
                                value={filtroDirectorio.idMunicipio}
                                onChange={handleChangeFiltroDirectorio}
                                select
                            >
                                <MenuItem value="">-- Seleccione municipio</MenuItem>
                                {catalogoMunicipio.map((x) => (
                                    <MenuItem key={x.idMunicipio} value={x.idMunicipio}>
                                        {x.nombreMunicipio}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="inherit" fullWidth onClick={handleClickBorrarFiltros}>
                                Borrar filtros
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="secondary" fullWidth onClick={handleClickConsultarDirectorioMedico}>
                                Aplicar filtro
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Drawer>
        </>
    );
};

export default AgendarCita;
