import {
    CentroMedicoFormModel,
    CentrosMedicosModel,
    CentrosMedicosRequestModel,
    DoctorFormulario,
    DoctorRequestFilter
} from '../types/administracion';
import { CitaModel, CitaRequestModel, DirectorioMedicoFilterModel, DoctorModel } from '../types/administracion';
import { DashboardResponseModel, getDefaultErrorResponse } from 'types/util';
import {
    DiaSemanaCatalogo,
    EspecialidadCatalogo,
    EstadoCatalogo,
    EstatusCitaCatalogo,
    EstatusSolicitudCatalogo,
    MedioContactoCatalogo,
    MunicipioCatalogo,
    SexoCatalogo,
    TipoMensajeCatalogo,
    TipoPagoCatalogo
} from '../types/catalogo';
import { DoctorAuthInfoModel, DoctorLoginAppRequest, PacienteRequestModel } from 'types/usuario';
import { GetPreguntaRequest, MensajeModel, MensajeRequest, PreguntaModel } from '../types/pregunta';
import { HorarioDiaModel, HorarioDisponibleModel, HorarioRequestModel, PrecioConfiguracionModel } from '../types/configuracion';
import { PacienteLoginAppRequest, PacienteModel, UsuarioPacienteModel } from '../types/usuario';

import { BASE_URL_MIDD } from '../config';

export async function postDoctorLoginApp(request: DoctorLoginAppRequest) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + '/api/usuario/doctor/login/app', {
            method: 'post',
            body: JSON.stringify(request),
            headers: {
                'content-type': 'application/json'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<DoctorAuthInfoModel>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<DoctorAuthInfoModel>();
    }
}

export async function postPacienteLoginApp(request: PacienteLoginAppRequest) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + '/api/usuario/cliente', {
            method: 'post',
            body: JSON.stringify(request),
            headers: {
                'content-type': 'application/json'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<UsuarioPacienteModel>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<UsuarioPacienteModel>();
    }
}
export async function getPreguntas(token: string, request: GetPreguntaRequest) {
    const params = `correo=${request.correo}&idEstado=${request.idEstado}&idSexo=${request.idSexo}&idMedioContacto=${request.idMedioContacto}&idDoctor=${request.idDoctor}&cerrado=${request.cerrado}`;
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + '/api/perfilamiento?' + params, {
            method: 'get',
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<PreguntaModel[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<PreguntaModel[]>();
    }
}
export async function getPreguntasPorPaciente(token: string) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + '/api/perfilamiento/pregunta-por-paciente', {
            method: 'get',
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<PreguntaModel[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<PreguntaModel[]>();
    }
}

export async function getPreguntaDetalle(idPregunta: string) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/perfilamiento/${idPregunta}`, {
            method: 'get',
            headers: {
                'content-type': 'application/json'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<PreguntaModel>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<PreguntaModel>();
    }
}

export async function postTomarPregunta(token: string, idPregunta: string) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/perfilamiento/${idPregunta}/tomar`, {
            method: 'post',
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<boolean>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<boolean>();
    }
}

export async function putCerrarPregunta(token: string, idPregunta: string) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/perfilamiento/${idPregunta}/cerrar`, {
            method: 'put',
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<boolean>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<boolean>();
    }
}

export async function getDiaSemana() {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + '/api/catalogo/dia-semana', {
            method: 'get',
            headers: {
                'content-type': 'application/json'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<DiaSemanaCatalogo[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<DiaSemanaCatalogo[]>();
    }
}

export async function getEstatusCita() {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + '/api/catalogo/estatus-cita', {
            method: 'get',
            headers: {
                'content-type': 'application/json'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<EstatusCitaCatalogo[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<EstatusCitaCatalogo[]>();
    }
}

export async function getEstatusSolicitud() {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + '/api/catalogo/estatus-solicitud', {
            method: 'get',
            headers: {
                'content-type': 'application/json'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<EstatusSolicitudCatalogo[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<EstatusSolicitudCatalogo[]>();
    }
}

export async function getMedioContacto() {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + '/api/catalogo/medio-contacto', {
            method: 'get',
            headers: {
                'content-type': 'application/json'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<MedioContactoCatalogo[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<MedioContactoCatalogo[]>();
    }
}

export async function getSexo() {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + '/api/catalogo/sexo', {
            method: 'get',
            headers: {
                'content-type': 'application/json'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<SexoCatalogo[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<SexoCatalogo[]>();
    }
}

export async function getTipoMensaje() {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + '/api/catalogo/tipo-mensaje', {
            method: 'get',
            headers: {
                'content-type': 'application/json'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<TipoMensajeCatalogo[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<TipoMensajeCatalogo[]>();
    }
}

export async function getTipoPago() {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + '/api/catalogo/tipo-pago', {
            method: 'get',
            headers: {
                'content-type': 'application/json'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<TipoPagoCatalogo[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<TipoPagoCatalogo[]>();
    }
}

export async function getEstado() {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + '/api/estados', {
            method: 'get',
            headers: {
                'content-type': 'application/json'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<EstadoCatalogo[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<EstadoCatalogo[]>();
    }
}

export async function getEspecialidad() {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + '/api/especialidad', {
            method: 'get',
            headers: {
                'content-type': 'application/json'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<EspecialidadCatalogo[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<EspecialidadCatalogo[]>();
    }
}

export async function getMunicipios(idEstado: string) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/estados/${idEstado}/municipio`, {
            method: 'get',
            headers: {
                'content-type': 'application/json'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<MunicipioCatalogo[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<MunicipioCatalogo[]>();
    }
}

export async function getMensajesApi(idPregunta: string) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/pregunta/${idPregunta}/mensaje`, {
            method: 'get',
            headers: {
                'content-type': 'application/json'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<MensajeModel[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<MensajeModel[]>();
    }
}

export async function postMensajeApi(idPregunta: string, request: MensajeRequest) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/pregunta/${idPregunta}/mensaje`, {
            method: 'post',
            body: JSON.stringify(request),
            headers: {
                'content-type': 'application/json'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<string>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<string>();
    }
}

export async function postMensajeArchivoApi(idPregunta: string, request: MensajeRequest, archivo: File) {
    try {
        const form = new FormData();
        form.append('tipoUsuario', request.idTipoUsuario.toString());
        form.append('contenidoMensaje', request.contenidoMensaje);
        form.append('archivo', archivo);

        const apiResponse = await fetch(BASE_URL_MIDD + `/api/pregunta/${idPregunta}/mensaje/archivo`, {
            method: 'post',
            body: form,
            headers: {
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<string>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<string>();
    }
}

export async function getCentrosMedicos(token: string, request: CentrosMedicosRequestModel) {
    const params = `idCentroMedico=${request.idCentroMedico}&nombreCentroMedico=${request.nombreCentroMedico}&calle=${request.calle}&numeroExt=${request.numeroExt}&colonia=${request.colonia}&idMunicipio=${request.idMunicipio}&idEstado=${request.idEstado}&codigoPostal=${request.codigoPostal}`;
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + '/api/centro-medico?' + params, {
            method: 'get',
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<CentrosMedicosModel[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<CentrosMedicosModel[]>();
    }
}

export async function postCentroMedico(token: string, request: CentroMedicoFormModel, fotoCentroMedico: File | null) {
    try {
        const form = new FormData();
        form.append('nombreCentroMedico', request.nombreCentroMedico);
        form.append('calle', request.calle);
        form.append('numeroExt', request.numeroExt);
        form.append('colonia', request.colonia);
        form.append('idMunicipio', request.idMunicipio);
        form.append('idEstado', request.idEstado);
        form.append('codigoPostal', request.codigoPostal);
        form.append('ubicacionMaps', request.ubicacionMaps);
        if (fotoCentroMedico) form.append('fotoCentroMedico', fotoCentroMedico);

        const apiResponse = await fetch(BASE_URL_MIDD + `/api/centro-medico`, {
            method: 'post',
            body: form,
            headers: {
                authorization: 'Bearer ' + token,
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<string>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<string>();
    }
}

export async function putCentroMedico(
    token: string,
    idCentroMedico: string,
    request: CentroMedicoFormModel,
    fotoCentroMedico: File | null
) {
    try {
        const form = new FormData();
        form.append('nombreCentroMedico', request.nombreCentroMedico);
        form.append('calle', request.calle);
        form.append('numeroExt', request.numeroExt);
        form.append('colonia', request.colonia);
        form.append('idMunicipio', request.idMunicipio);
        form.append('idEstado', request.idEstado);
        form.append('codigoPostal', request.codigoPostal);
        form.append('ubicacionMaps', request.ubicacionMaps);
        if (fotoCentroMedico) form.append('fotoCentroMedico', fotoCentroMedico);

        const apiResponse = await fetch(BASE_URL_MIDD + `/api/centro-medico/${idCentroMedico}`, {
            method: 'put',
            body: form,
            headers: {
                authorization: 'Bearer ' + token,
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<string>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<string>();
    }
}

export async function deleteCentroMedico(token: string, idCentroMedico: string) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/centro-medico/${idCentroMedico}`, {
            method: 'delete',
            headers: {
                authorization: 'Bearer ' + token,
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<boolean>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<boolean>();
    }
}

export async function getHorarioDoctor(token: string) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + '/api/doctor/horario', {
            method: 'get',
            headers: {
                authorization: 'Bearer ' + token,
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<HorarioDiaModel[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<HorarioDiaModel[]>();
    }
}

export async function postHorarioDoctor(token: string, request: HorarioRequestModel) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + '/api/doctor/horario', {
            method: 'post',
            body: JSON.stringify(request),
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token,
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<string>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<string>();
    }
}

export async function putHorarioDoctor(token: string, idDoctorHorario: string, request: HorarioRequestModel) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/doctor/horario/${idDoctorHorario}`, {
            method: 'put',
            body: JSON.stringify(request),
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token,
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<string>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<string>();
    }
}

export async function deleteHorarioDoctor(token: string, idDoctorHorario: string) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/doctor/horario/${idDoctorHorario}`, {
            method: 'delete',
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token,
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<boolean>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<boolean>();
    }
}

export async function getDisponibilidadHorario(idDoctor: string, fecha: string = '') {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/doctor/horario/${idDoctor}/agenda-pacientes?fecha=${fecha}`, {
            method: 'get',
            headers: {
                'content-type': 'application/json',
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<HorarioDisponibleModel>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<HorarioDisponibleModel>();
    }
}

export async function getPacientes(token: string) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/pacientes`, {
            method: 'get',
            headers: {
                'content-type': 'application/json',
                accept: '*/*',
                authorization: 'Bearer ' + token
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<PacienteModel[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<PacienteModel[]>();
    }
}

export async function getDoctores(token: string, request: DoctorRequestFilter) {
    try {
        const {
            apellidos,
            cedulaProfesional,
            codigoPostal,
            direccion,
            idDoctor,
            idEspecialidad,
            idEstado,
            idEstatusSolicitud,
            idMunicipio,
            idUsuario,
            nombreConsultorio,
            nombreHospital,
            nombres
        } = request;

        const params = `idDoctor=${idDoctor}&idUsuario=${idUsuario}&idEspecialidad=${idEspecialidad}&nombres=${nombres}&apellidos=${apellidos}&direccion=${direccion}&idEstado=${idEstado}&idMunicipio=${idMunicipio}&cedulaProfesional=${cedulaProfesional}&nombreHospital=${nombreHospital}&nombreConsultorio=${nombreConsultorio}&codigoPostal=${codigoPostal}&idEstatusSolicitud=${idEstatusSolicitud}`;

        const apiResponse = await fetch(BASE_URL_MIDD + `/api/doctor?` + params, {
            method: 'get',
            headers: {
                authorization: 'Bearer ' + token,
                'content-type': 'application/json',
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<DoctorModel[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<DoctorModel[]>();
    }
}

export async function postDoctor(token: string, request: DoctorFormulario, fotoCentroMedico: File | null) {
    try {
        const form = new FormData();
        form.append('cedulaProfesional', request.cedulaProfesional);
        form.append('correo', request.correo);
        form.append('idEstado', request.idEstado);
        form.append('idMunicipio', request.idMunicipio);
        form.append('nombres', request.nombres);
        form.append('telefonoPersonal', request.telefonoPersonal);
        form.append('idEspecialidad', request.idEspecialidad);
        if (fotoCentroMedico) form.append('fotoPerfil', fotoCentroMedico);

        const apiResponse = await fetch(BASE_URL_MIDD + `/api/doctor`, {
            method: 'post',
            body: form,
            headers: {
                authorization: 'Bearer ' + token,
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<string>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<string>();
    }
}

export async function putDoctor(token: string, idDoctor: string, request: DoctorFormulario, fotoCentroMedico: File | null) {
    try {
        const form = new FormData();
        form.append('cedulaProfesional', request.cedulaProfesional);
        form.append('correo', request.correo);
        form.append('idEstado', request.idEstado);
        form.append('idMunicipio', request.idMunicipio);
        form.append('nombres', request.nombres);
        form.append('telefonoPersonal', request.telefonoPersonal);
        form.append('idEspecialidad', request.idEspecialidad);
        if (fotoCentroMedico) form.append('fotoPerfil', fotoCentroMedico);

        const apiResponse = await fetch(BASE_URL_MIDD + `/api/doctor/${idDoctor}`, {
            method: 'put',
            body: form,
            headers: {
                authorization: 'Bearer ' + token,
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<string>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<string>();
    }
}

export async function putDoctorAutorizar(token: string, idDoctor: string) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/doctor/${idDoctor}/autorizar`, {
            method: 'put',
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token,
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<boolean>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<boolean>();
    }
}

export async function putDoctorRechazar(token: string, idDoctor: string) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/doctor/${idDoctor}/rechazar`, {
            method: 'put',
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token,
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<boolean>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<boolean>();
    }
}

export async function postDoctorPrecio(token: string, request: PrecioConfiguracionModel) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + '/api/doctor/precio', {
            method: 'post',
            body: JSON.stringify(request),
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token,
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<boolean>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<boolean>();
    }
}

export async function postCita(token: string, request: CitaRequestModel) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/cita`, {
            method: 'post',
            body: JSON.stringify(request),
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token,
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<string>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<string>();
    }
}

export async function postCitaPaciente(token: string, idPaciente: string, request: CitaRequestModel) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/cita/${idPaciente}/programar`, {
            method: 'post',
            body: JSON.stringify(request),
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token,
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<string>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<string>();
    }
}

export async function getCitaDetalle(token: string, idCita: string) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/cita/${idCita}/detalle`, {
            method: 'get',
            headers: {
                'content-type': 'application/json',
                accept: '*/*',
                authorization: 'Bearer ' + token
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<CitaModel>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<CitaModel>();
    }
}

export async function getCitasDoctor(token: string) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/cita/doctor`, {
            method: 'get',
            headers: {
                'content-type': 'application/json',
                accept: '*/*',
                authorization: 'Bearer ' + token
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<CitaModel[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<CitaModel[]>();
    }
}

export async function getCitasPaciente(token: string) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/cita/paciente`, {
            method: 'get',
            headers: {
                'content-type': 'application/json',
                accept: '*/*',
                authorization: 'Bearer ' + token
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<CitaModel[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<CitaModel[]>();
    }
}
export async function getDirectorioMedico(request: DirectorioMedicoFilterModel) {
    try {
        const params = `?idEspecialidad=${request.idEspecialidad}&idEstado=${request.idEstado}&idMunicipio=${request.idMunicipio}`;
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/doctor/menu` + params, {
            method: 'get',
            headers: {
                'content-type': 'application/json',
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<DoctorModel[]>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<DoctorModel[]>();
    }
}

export async function getCitaResumenCompra(token: string, sessionId: string) {
    try {
        const params = `?sessionId=${sessionId}`;
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/cita/resumen-compra` + params, {
            method: 'get',
            headers: {
                'content-type': 'application/json',
                accept: '*/*',
                authorization: 'Bearer ' + token
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<CitaModel>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<CitaModel>();
    }
}

export async function postPacientePerfil(token: string, request: PacienteRequestModel) {
    try {
        const apiResponse = await fetch(BASE_URL_MIDD + `/api/pacientes/perfil`, {
            method: 'post',
            body: JSON.stringify(request),
            headers: {
                'content-type': 'application/json',
                authorization: 'Bearer ' + token,
                accept: '*/*'
            }
        });

        const response = (await apiResponse.json()) as DashboardResponseModel<boolean>;

        return response;
    } catch (error) {
        return getDefaultErrorResponse<boolean>();
    }
}
