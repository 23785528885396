// types
import { ConfigProps } from 'types/config';
import LAYOUT_CONST from 'constant';

export const JWT_API = {
    secret: 'SECRET-KEY',
    timeout: '1 days'
};

export const FIREBASE_API = {
    apiKey: 'AIzaSyBCM2d0-V9LXyPB6OBvUAT0bvb7G7mhHvc',
    authDomain: 'hd-pacientes.firebaseapp.com',
    projectId: 'hd-pacientes',
    storageBucket: 'hd-pacientes.appspot.com',
    messagingSenderId: '36591713549',
    appId: '1:36591713549:web:4124c61b65a8ddcf1c4444',
    measurementId: 'G-Y89RF5P04P'
};

export const AUTH0_API = {
    client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
    domain: 'dev-w0-vxep3.us.auth0.com'
};

export const AWS_API = {
    poolId: 'us-east-1_AOfOTXLvD',
    appClientId: '3eau2osduslvb7vks3vsh9t7b0'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/perfilamiento/preguntas';

export const BASE_URL_MIDD = 'https://58nq5poqlf.execute-api.us-east-1.amazonaws.com/midd-services';
//export const BASE_URL_MIDD = 'https://localhost:3002';

export const HORIZONTAL_MAX_ITEM = 6;

const config: ConfigProps = {
    layout: LAYOUT_CONST.VERTICAL_LAYOUT, // vertical, horizontal
    drawerType: LAYOUT_CONST.DEFAULT_DRAWER, // default, mini-drawer
    fontFamily: `'Poppins', 'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
