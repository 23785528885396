import { Grid, Link, Typography } from '@mui/material';
import { format } from 'date-fns';

import { PreguntaModel } from 'types/pregunta';
import SubCard from 'ui-component/cards/SubCard';

interface DetallePreguntaProps {
    detallePregunta: PreguntaModel;
}

const DetallePregunta = ({ detallePregunta }: DetallePreguntaProps) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h4">Detalles de perfilamiento</Typography>
            </Grid>
            <Grid item xs={12}>
                <SubCard title="Paciente dice:">
                    <Typography>{detallePregunta.pregunta}</Typography>
                </SubCard>
            </Grid>
            <Grid item xs={12}>
                <SubCard title="Datos capturados:">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="caption">Correo</Typography>
                            <Typography>{detallePregunta.correo}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="caption">Estado</Typography>
                            <Typography>{detallePregunta.nombreEstado}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="caption">Fecha de nacimiento</Typography>
                            <Typography>{format(new Date(detallePregunta.fechaNacimiento), 'dd/MM/yyyy')}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="caption">Sexo</Typography>
                            <Typography>{detallePregunta.descripcionSexo}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="caption">Medio de contacto seleccionado</Typography>
                            <Typography>{detallePregunta.descripcionMedioContacto}</Typography>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
            <Grid item xs={12}>
                <SubCard title="Cuadro de alergias:">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="caption">Alergia al látex</Typography>
                            <Typography>{detallePregunta.alergiaLatex ? 'Si' : 'No'}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="caption">Alergias a alimentos</Typography>
                            <Typography>
                                <ul>
                                    {detallePregunta.alergiasAlimentos.length > 0 &&
                                        detallePregunta.alergiasAlimentos.map((x) => <li key={x.idAlimento}>{x.nombreAlimento}</li>)}
                                    <li>{detallePregunta.otraAlergiaAlimento}</li>
                                </ul>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="caption">Alergias a antibióticos</Typography>
                            <Typography>
                                <ul>
                                    {detallePregunta.alergiasAntibioticos.length > 0 &&
                                        detallePregunta.alergiasAntibioticos.map((x) => (
                                            <li key={x.idAntibiotico}>{x.nombreAntibiotico}</li>
                                        ))}
                                    <li>{detallePregunta.otraAlergiaAntibiotico}</li>
                                </ul>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="caption">Alergias a medicamentos</Typography>
                            <Typography>
                                <ul>
                                    {detallePregunta.alergiasMedicamentos.length > 0 &&
                                        detallePregunta.alergiasMedicamentos.map((x) => (
                                            <li key={x.idMedicamento}>{x.nombreMedicamento}</li>
                                        ))}
                                    <li>{detallePregunta.otraAlergiaMedicamento}</li>
                                </ul>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="caption">Enfermedades</Typography>
                            <Typography>
                                <ul>
                                    {detallePregunta.enfermedades.length > 0 &&
                                        detallePregunta.enfermedades.map((x) => <li key={x.idEnfermedad}>{x.nombreEnfermedad}</li>)}
                                    <li>{detallePregunta.otraEnfermedad}</li>
                                </ul>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="caption">Medicamentos actuales</Typography>
                            <Typography>
                                <ul>
                                    {detallePregunta.medicamentosActuales.length > 0 &&
                                        detallePregunta.medicamentosActuales.map((x) => (
                                            <li key={x.idMedicamento}>{x.nombreMedicamento}</li>
                                        ))}
                                    <li>{detallePregunta.medicamentoActual}</li>
                                </ul>
                            </Typography>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
            <Grid item xs={12}>
                <SubCard title="Archivos:">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {detallePregunta.archivos.length > 0 ? (
                                detallePregunta.archivos.map((x) => (
                                    <Link
                                        key={x.consecutivo}
                                        href={x.llaveRuta}
                                        target="_blank"
                                        rel="noopener"
                                        style={{ color: 'inherit' }}
                                    >
                                        <Typography>{x.nombreArchivo}</Typography>
                                    </Link>
                                ))
                            ) : (
                                <Typography>No hay archivos cargados</Typography>
                            )}
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
        </Grid>
    );
};

export default DetallePregunta;
