import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    Divider,
    Drawer,
    Grid,
    IconButton,
    Link,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { MensajeModel, PreguntaModel } from 'types/pregunta';
import { Theme, styled, useTheme } from '@mui/material/styles';

import styles from '../../../assets/scss/chat.module.scss';
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from 'types/util';
import { appDrawerWidth as drawerWidth, gridSpacing } from '../../../store/constant';
import { getMensajesApi, getPreguntaDetalle, postMensajeApi, postMensajeArchivoApi } from 'api/calls';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import AttachmentTwoToneIcon from '@mui/icons-material/AttachmentTwoTone';
import DetallePregunta from './DetallePregunta';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import MainCard from 'ui-component/cards/MainCard';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import { useDropzone } from 'react-dropzone';
import { useLayout } from 'contexts/LayoutContext';
import { useParams } from 'react-router-dom';
import LogoHeyDoctor from '../../../assets/images/logos.png';
import useAuth from 'hooks/useAuth';
import { format } from 'date-fns';

const Main = styled('main', { shouldForwardProp: (prop: string) => prop !== 'open' })(
    ({ theme, open }: { theme: Theme; open: boolean }) => ({
        flexGrow: 1,
        paddingLeft: open ? theme.spacing(3) : 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: `-${drawerWidth}px`,
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 0,
            marginLeft: 0
        },
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.shorter
            }),
            marginLeft: 0
        })
    })
);

const ChatPregunta = () => {
    const theme = useTheme();
    const scrollRef = useRef();
    const { data } = useAuth();

    const { idPregunta } = useParams();
    const { handleOpenBackdrop, handleCloseBackdrop } = useLayout();
    const [archivosPendientesPorSubir, setArchivosPendientesPorSubir] = useState<File[]>([]);
    const [modalOpenPreguntaDetalle, setModalOpenPreguntaDetalle] = useState(false);
    const [intervalChat, setIntervalChat] = useState<NodeJS.Timer | number>(0);

    const handleOpenPreguntaDetalle = () => setModalOpenPreguntaDetalle(true);
    const handleClosePreguntaDetalle = () => setModalOpenPreguntaDetalle(false);

    const handleClickDeleteFile = (file: File) => {
        setArchivosPendientesPorSubir(archivosPendientesPorSubir.filter((x) => file !== x));
    };

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open } = useDropzone({
        noClick: true,
        maxFiles: 3,
        maxSize: 10485760,
        multiple: true,
        onDropAccepted: (files: File[]) => setArchivosPendientesPorSubir([...archivosPendientesPorSubir, ...files])
    });

    const styleDropzone = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {})
        }),
        [isDragActive, isDragAccept, isDragReject]
    );

    const [listaMensajes, setListaMensajes] = useState<MensajeModel[]>([]);
    const [detallePregunta, setDetallePregunta] = useState<PreguntaModel | null>(null);
    const [contenidoMensaje, setContenidoMensaje] = useState('');

    const handleChangeContenidoMensaje = (e: any) => {
        setContenidoMensaje(e.target.value);
    };

    const handleClickGetMensajes = async () => {
        handleOpenBackdrop();
        const response = await getMensajesApi(idPregunta as string);
        handleCloseBackdrop();
        if (!response.hasError) {
            setListaMensajes(response.data as MensajeModel[]);
        }
    };

    const HandleClickGetDetallePregunta = async () => {
        const response = await getPreguntaDetalle(idPregunta as string);

        if (!response.hasError) {
            setDetallePregunta(response.data as PreguntaModel);
        }
    };

    const handleClickSendMessage = async () => {
        if (archivosPendientesPorSubir.length === 0 && !!contenidoMensaje) {
            const response = await postMensajeApi(idPregunta as string, {
                contenidoMensaje,
                idTipoUsuario: 1
            });

            if (!response.hasError) {
                setContenidoMensaje('');

                await handleClickGetMensajes();
            }
        }

        if (archivosPendientesPorSubir.length > 0) {
            for (let i = 0; i < archivosPendientesPorSubir.length; i++) {
                const file = archivosPendientesPorSubir[i];
                handleOpenBackdrop();
                const response = await postMensajeArchivoApi(
                    idPregunta as string,
                    {
                        contenidoMensaje: i === 0 ? contenidoMensaje : '',
                        idTipoUsuario: 1
                    },
                    file
                );
                handleCloseBackdrop();

                if (response.hasError) {
                    alert('No se pudo cargar el archivo ' + file.name);
                }
            }
            setContenidoMensaje('');
            setArchivosPendientesPorSubir([]);
            await handleClickGetMensajes();
        }
    };

    const renderText = (value: string) => {
        const newString = value.replace(/(?:\r\n|\r|\n)/g, '<br>');
        var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        return newString.replace(exp, '<a href="$1" target="_blank" style="color:initial;">$1</a>');
    };

    useEffect(() => {
        handleClickGetMensajes();
        HandleClickGetDetallePregunta();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        return () => {
            clearInterval(intervalChat);
        };
        //eslint-disable-next-line
    }, [intervalChat]);

    useEffect(() => {
        clearInterval(intervalChat);
        const newInterval = setInterval(() => {
            getMensajesApi(idPregunta as string).then((response) => {
                if (!response.hasError) {
                    console.log('prepare', response.data?.length, listaMensajes.length);
                    if (response.data?.length !== listaMensajes.length) {
                        console.log('chage', response.data?.length, listaMensajes.length);

                        setListaMensajes(response.data as MensajeModel[]);
                    }
                }
            });
        }, 10000);
        setIntervalChat(newInterval);
        //eslint-disable-next-line
    }, [listaMensajes]);

    useLayoutEffect(() => {
        if (scrollRef?.current) {
            // @ts-ignore
            scrollRef.current.scrollIntoView();
        }
    });

    return (
        <>
            {!data && (
                <div className={styles.container}>
                    <div>
                        <img src={LogoHeyDoctor} alt="" style={{ height: 40 }} />
                    </div>
                    <div className={styles.links}>
                        <div className={styles.register}>
                            <a href="https://pacientes.heydoctor.com.mx/register">Registrarse ahora</a>
                        </div>
                        <div>
                            <a href="https://pacientes.heydoctor.com.mx/login" className={styles.login}>
                                Iniciar sesión
                            </a>
                        </div>
                    </div>
                </div>
            )}
            <Box sx={{ display: 'flex' }}>
                <Main theme={theme} open>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs>
                            <MainCard
                                sx={{
                                    bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50'
                                }}
                            >
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12}>
                                        <Grid container alignItems="center" spacing={0.5}>
                                            <Grid item>
                                                <Grid container spacing={2} alignItems="center" sx={{ flexWrap: 'nowrap' }}>
                                                    <Grid item>
                                                        <Avatar>DR</Avatar>
                                                    </Grid>
                                                    <Grid item sm zeroMinWidth>
                                                        <Grid container spacing={0} alignItems="center">
                                                            <Grid item xs={12}>
                                                                <Typography variant="h4" component="div">
                                                                    {!detallePregunta?.nombresDoctor
                                                                        ? 'Estamos asignando un doctor, espere...'
                                                                        : detallePregunta?.nombresDoctor}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item sm zeroMinWidth />

                                            <Grid item>
                                                <Tooltip title="Detalle de pregunta">
                                                    <IconButton size="large" onClick={handleOpenPreguntaDetalle}>
                                                        <ErrorTwoToneIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <Divider sx={{ mt: theme.spacing(2) }} />
                                    </Grid>
                                    <PerfectScrollbar
                                        style={{ width: '100%', height: 'calc(100vh - 350px)', overflowX: 'hidden', minHeight: 525 }}
                                    >
                                        <CardContent>
                                            <Grid item xs={12}>
                                                <Grid container spacing={gridSpacing}>
                                                    {listaMensajes.map((x) =>
                                                        x.idTipoUsuario === 2 ? (
                                                            <Grid item xs={12} key={x.idMensaje}>
                                                                <Grid container spacing={gridSpacing}>
                                                                    <Grid item xs={12} sm={7}>
                                                                        <Card
                                                                            sx={{
                                                                                display: 'inline-block',
                                                                                float: 'left',
                                                                                background:
                                                                                    theme.palette.mode === 'dark'
                                                                                        ? theme.palette.dark[900]
                                                                                        : theme.palette.secondary.light
                                                                            }}
                                                                        >
                                                                            <CardContent sx={{ p: 2, pb: '16px !important' }}>
                                                                                <Grid container spacing={1}>
                                                                                    <Grid item xs={12}>
                                                                                        {x.idTipoMensaje === 1 && !!x.contenidoMensaje && (
                                                                                            <Typography
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html: renderText(x.contenidoMensaje)
                                                                                                }}
                                                                                                variant="body2"
                                                                                            />
                                                                                        )}
                                                                                        {x.idTipoMensaje === 2 && (
                                                                                            <>
                                                                                                <img
                                                                                                    src={x.llaveRuta}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        maxWidth: 400,
                                                                                                        maxHeight: 400,
                                                                                                        borderRadius: 10
                                                                                                    }}
                                                                                                />
                                                                                                <br />
                                                                                                {!!x.contenidoMensaje && (
                                                                                                    <Typography
                                                                                                        dangerouslySetInnerHTML={{
                                                                                                            __html: renderText(
                                                                                                                x.contenidoMensaje
                                                                                                            )
                                                                                                        }}
                                                                                                        variant="body2"
                                                                                                    />
                                                                                                )}
                                                                                                <Link
                                                                                                    href={x.llaveRuta}
                                                                                                    target="_blank"
                                                                                                    rel="noopener"
                                                                                                    style={{ color: 'inherit' }}
                                                                                                >
                                                                                                    <Typography variant="button">
                                                                                                        {x.nombreArchivo}
                                                                                                    </Typography>
                                                                                                </Link>
                                                                                            </>
                                                                                        )}
                                                                                    </Grid>
                                                                                    <Grid item xs={12}>
                                                                                        <Typography align="right" variant="subtitle2">
                                                                                            Doctor -{' '}
                                                                                            {format(
                                                                                                new Date(x.horaEnvio + 'Z'),
                                                                                                'dd/MM/yyyy hh:mm aaa'
                                                                                            )}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </CardContent>
                                                                        </Card>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        ) : (
                                                            <Grid item xs={12} key={x.idMensaje}>
                                                                <Grid container spacing={gridSpacing}>
                                                                    <Grid item xs={2} />
                                                                    <Grid item xs={10}>
                                                                        <Card
                                                                            sx={{
                                                                                display: 'inline-block',
                                                                                float: 'right',
                                                                                bgcolor:
                                                                                    theme.palette.mode === 'dark'
                                                                                        ? 'grey.600'
                                                                                        : theme.palette.primary.light
                                                                            }}
                                                                        >
                                                                            <CardContent
                                                                                sx={{
                                                                                    p: 2,
                                                                                    pb: '16px !important',
                                                                                    width: 'fit-content',
                                                                                    ml: 'auto'
                                                                                }}
                                                                            >
                                                                                <Grid container spacing={1}>
                                                                                    <Grid item xs={12}>
                                                                                        {x.idTipoMensaje === 1 && !!x.contenidoMensaje && (
                                                                                            <Typography
                                                                                                dangerouslySetInnerHTML={{
                                                                                                    __html: renderText(x.contenidoMensaje)
                                                                                                }}
                                                                                                variant="body2"
                                                                                                color={
                                                                                                    theme.palette.mode === 'dark'
                                                                                                        ? 'dark.900'
                                                                                                        : ''
                                                                                                }
                                                                                            />
                                                                                        )}
                                                                                        {x.idTipoMensaje === 2 && (
                                                                                            <>
                                                                                                <img
                                                                                                    src={x.llaveRuta}
                                                                                                    alt=""
                                                                                                    style={{
                                                                                                        maxWidth: 400,
                                                                                                        maxHeight: 400,
                                                                                                        borderRadius: 10
                                                                                                    }}
                                                                                                />
                                                                                                <br />
                                                                                                {!!x.contenidoMensaje && (
                                                                                                    <Typography
                                                                                                        dangerouslySetInnerHTML={{
                                                                                                            __html: renderText(
                                                                                                                x.contenidoMensaje
                                                                                                            )
                                                                                                        }}
                                                                                                        variant="body2"
                                                                                                        color={
                                                                                                            theme.palette.mode === 'dark'
                                                                                                                ? 'dark.900'
                                                                                                                : ''
                                                                                                        }
                                                                                                    />
                                                                                                )}
                                                                                                <Link
                                                                                                    href={x.llaveRuta}
                                                                                                    target="_blank"
                                                                                                    rel="noopener"
                                                                                                    style={{ color: 'inherit' }}
                                                                                                >
                                                                                                    <Typography
                                                                                                        variant="button"
                                                                                                        color={
                                                                                                            theme.palette.mode === 'dark'
                                                                                                                ? 'dark.900'
                                                                                                                : ''
                                                                                                        }
                                                                                                    >
                                                                                                        {x.nombreArchivo}
                                                                                                    </Typography>
                                                                                                </Link>
                                                                                            </>
                                                                                        )}
                                                                                    </Grid>

                                                                                    <Grid item xs={12}>
                                                                                        <Typography
                                                                                            align="right"
                                                                                            variant="subtitle2"
                                                                                            color={
                                                                                                theme.palette.mode === 'dark'
                                                                                                    ? 'dark.900'
                                                                                                    : ''
                                                                                            }
                                                                                        >
                                                                                            Yo -{' '}
                                                                                            {format(
                                                                                                new Date(x.horaEnvio + 'Z'),
                                                                                                'dd/MM/yyyy hh:mm aaa'
                                                                                            )}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </CardContent>
                                                                        </Card>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    )}
                                                    {listaMensajes.length < 1 && (
                                                        <Grid item xs={12} display="flex" justifyContent="center">
                                                            <Typography>No hay mensajes disponibles</Typography>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                            {/* @ts-ignore */}
                                            <span ref={scrollRef} />
                                        </CardContent>
                                    </PerfectScrollbar>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs zeroMinWidth>
                                                <div {...getRootProps({ style: styleDropzone as React.CSSProperties })}>
                                                    <input {...getInputProps()} />
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        maxRows={5}
                                                        autoFocus
                                                        label="Escribe el mensaje aquí o arrastra un archivo (max 10MB) para enviar"
                                                        placeholder="Escribe el mensaje aquí o arrastra un archivo (max 10MB) para enviar"
                                                        value={contenidoMensaje}
                                                        onChange={handleChangeContenidoMensaje}
                                                        onKeyPress={(e) => {
                                                            if (e.key === 'Enter' && !e.shiftKey) {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                handleClickSendMessage();
                                                            }
                                                        }}
                                                    />
                                                    {archivosPendientesPorSubir.length > 0 && (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'start',
                                                                padding: 10,
                                                                width: '100%',
                                                                gap: 5,
                                                                flexWrap: 'wrap'
                                                            }}
                                                        >
                                                            {archivosPendientesPorSubir.map((x, i) => (
                                                                <Chip
                                                                    key={i}
                                                                    label={x.name}
                                                                    color="secondary"
                                                                    onDelete={() => handleClickDeleteFile(x)}
                                                                />
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item>
                                                <IconButton size="large" onClick={open}>
                                                    <AttachmentTwoToneIcon />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton color="primary" size="large" onClick={handleClickSendMessage}>
                                                    <SendTwoToneIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </MainCard>
                        </Grid>
                    </Grid>
                </Main>
            </Box>
            <Drawer open={modalOpenPreguntaDetalle} onClose={handleClosePreguntaDetalle} anchor="right">
                <Box p={3} width={500}>
                    {detallePregunta && <DetallePregunta detallePregunta={detallePregunta} />}
                </Box>
            </Drawer>
        </>
    );
};

export default ChatPregunta;
