import 'firebase/compat/auth';

import { FirebaseContextType, InitialLoginContextProps } from 'types/auth';
// action - state management
import { LOGIN, LOGOUT } from 'store/actions';
import React, { createContext, useEffect, useReducer } from 'react';
import { postDoctorLoginApp, postPacienteLoginApp } from 'api/calls';
import { FIREBASE_API } from 'config';
// project imports
import Loader from 'ui-component/Loader';
import accountReducer from 'store/accountReducer';
// third-party
import firebase from 'firebase/compat/app';

// firebase initialize
if (!firebase.apps.length) {
    firebase.initializeApp(FIREBASE_API);
}

// const
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext<FirebaseContextType | null>(null);

export const FirebaseProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(
        () =>
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    const response = await postPacienteLoginApp({ correo: user.email as string, nombre: user.displayName as string });
                    if (response.hasError) {
                        dispatch({
                            type: LOGOUT
                        });
                    } else {
                        dispatch({
                            type: LOGIN,
                            payload: {
                                isLoggedIn: true,
                                data: response.data,
                                user: {
                                    id: user.uid,
                                    email: user.email!,
                                    name: user.displayName || 'John Doe'
                                }
                            }
                        });
                    }
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    const firebaseEmailPasswordSignIn = (email: string, password: string) => firebase.auth().signInWithEmailAndPassword(email, password);

    const firebaseGoogleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();

        return firebase.auth().signInWithPopup(provider);
    };

    // const firebaseRegister = async (email: string, password: string) => firebase.auth().createUserWithEmailAndPassword(email, password);

    const firebaseRegister = async (email: string, password: string, displayName: string) => {
        const userCredentials = await firebase.auth().createUserWithEmailAndPassword(email, password);

        await userCredentials.user?.updateProfile({
            displayName
        });

        window.location.reload();

        return userCredentials;
    };

    const logout = () => firebase.auth().signOut();

    const resetPassword = async (email: string) => {
        await firebase.auth().sendPasswordResetEmail(email);
    };

    const validateResetPasswordCode = async (code: string) => {
        try {
            const email = await firebase.auth().verifyPasswordResetCode(code);
            console.log(email);

            return email;
        } catch (error) {
            return null;
        }
    };

    const updatePassword = async (code: string, email: string, password: string) => {
        await firebase.auth().confirmPasswordReset(code, password);
        await firebase.auth().signInWithEmailAndPassword(email, password);
    };

    const updateProfile = () => {};
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <>
            <FirebaseContext.Provider
                value={{
                    ...state,
                    firebaseRegister,
                    firebaseEmailPasswordSignIn,
                    login: () => {},
                    firebaseGoogleSignIn,
                    logout,
                    resetPassword,
                    updateProfile,
                    updatePassword,
                    validateResetPasswordCode
                }}
            >
                {children}
            </FirebaseContext.Provider>
        </>
    );
};

export default FirebaseContext;
