import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { getSexo, postPacientePerfil } from 'api/calls';
import { useLayout } from 'contexts/LayoutContext';
import { format } from 'date-fns';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { SexoCatalogo } from 'types/catalogo';
import { PacientePerfilModel } from 'types/usuario';
import MainCard from 'ui-component/cards/MainCard';

const Perfil = () => {
    const { data } = useAuth();
    const { handleOpenBackdrop, handleCloseBackdrop } = useLayout();

    const [catalogoSexo, setCatalogoSexo] = useState<SexoCatalogo[]>([]);
    const [formularioPerfil, setFormularioPerfil] = useState<PacientePerfilModel>({
        nombre: '',
        telefono: '',
        fechaNacimiento: null,
        idSexo: '',
        estatura: '',
        peso: '',
        padecimiento: ''
    });

    const handleChangeFormulario = (e: any) => {
        setFormularioPerfil({
            ...formularioPerfil,
            [e.target.name]: e.target.value
        });
    };

    const handleClickObtenerCatalogoSexo = () => {
        getSexo().then((response) => {
            if (!response.hasError) {
                setCatalogoSexo(response.data as SexoCatalogo[]);
            }
        });
    };

    const handleClickGuardarDatos = () => {
        const { estatura, fechaNacimiento, idSexo, nombre, padecimiento, peso, telefono } = formularioPerfil;
        handleOpenBackdrop();
        postPacientePerfil(data?.token as string, {
            estatura: !estatura ? null : parseFloat(estatura),
            fechaNacimiento: !fechaNacimiento ? null : format(fechaNacimiento, 'yyyy-MM-dd'),
            idSexo: !idSexo ? null : parseInt(idSexo),
            nombre: !nombre ? null : nombre,
            padecimiento: !padecimiento ? null : padecimiento,
            peso: !peso ? null : parseFloat(peso),
            telefono: !telefono ? null : telefono
        }).then((response) => {
            handleCloseBackdrop();
            if (!response.hasError) {
                window.location.reload();
            }
        });
    };

    useEffect(() => {
        handleClickObtenerCatalogoSexo();
        if (!!data) {
            setFormularioPerfil({
                estatura: !data.datosPaciente.estatura ? '' : data.datosPaciente.estatura.toString(),
                fechaNacimiento: !data.datosPaciente.fechaNacimiento ? null : new Date(data.datosPaciente.fechaNacimiento),
                idSexo: !data.datosPaciente.idSexo ? '' : data.datosPaciente.idSexo.toString(),
                nombre: !data.datosPaciente.nombre ? '' : data.datosPaciente.nombre,
                padecimiento: !data.datosPaciente.padecimiento ? '' : data.datosPaciente.padecimiento,
                peso: !data.datosPaciente.peso ? '' : data.datosPaciente.peso.toString(),
                telefono: !data.datosPaciente.telefono ? '' : data.datosPaciente.telefono
            });
        }
        //eslint-disable-next-line
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
                <MainCard title="Actualizar datos de perfil">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="nombre"
                                label="Nombres:"
                                fullWidth
                                value={formularioPerfil.nombre}
                                onChange={handleChangeFormulario}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="telefono"
                                label="Teléfono/Celular:"
                                fullWidth
                                value={formularioPerfil.telefono}
                                onChange={handleChangeFormulario}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                renderInput={(props) => <TextField fullWidth {...props} helperText="" required />}
                                label="Fecha de nacimiento:"
                                views={['year', 'month', 'day']}
                                value={formularioPerfil.fechaNacimiento}
                                onChange={(newValue: Date | null) =>
                                    setFormularioPerfil({
                                        ...formularioPerfil,
                                        fechaNacimiento: newValue
                                    })
                                }
                                inputFormat="dd/MM/yyyy"
                                disableFuture
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="idSexo"
                                label="Sexo:"
                                fullWidth
                                value={formularioPerfil.idSexo}
                                onChange={handleChangeFormulario}
                                select
                            >
                                <MenuItem value="">--Seleccionar sexo--</MenuItem>
                                {catalogoSexo.map((x) => (
                                    <MenuItem key={x.idSexo} value={x.idSexo.toString()}>
                                        {x.descripcionSexo}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="estatura"
                                label="Estatura (en cm):"
                                fullWidth
                                value={formularioPerfil.estatura}
                                onChange={handleChangeFormulario}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="peso"
                                label="Peso (en kg):"
                                fullWidth
                                value={formularioPerfil.peso}
                                onChange={handleChangeFormulario}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="padecimiento"
                                label="Padecimientos:"
                                fullWidth
                                value={formularioPerfil.padecimiento}
                                onChange={handleChangeFormulario}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="end">
                            <Button variant="contained" color="secondary" onClick={handleClickGuardarDatos}>
                                Guardar datos de perfil
                            </Button>
                        </Grid>
                    </Grid>
                </MainCard>
            </Grid>
        </Grid>
    );
};

export default Perfil;
