import { createContext, useContext, useState } from 'react';

export interface LayourContextProps {
    backdropOpen: boolean;
    handleOpenBackdrop: () => void;
    handleCloseBackdrop: () => void;
}

const initProps: LayourContextProps = {
    backdropOpen: false,
    handleOpenBackdrop: () => {},
    handleCloseBackdrop: () => {}
};

export const LayoutContext = createContext(initProps);

export const LayoutProvider = (props: any) => {
    const [backdropOpen, setBackdropOpen] = useState(false);

    const handleOpenBackdrop = () => {
        setBackdropOpen(true);
    };

    const handleCloseBackdrop = () => {
        setBackdropOpen(false);
    };
    return (
        <LayoutContext.Provider value={{ backdropOpen, handleOpenBackdrop, handleCloseBackdrop }}>{props.children}</LayoutContext.Provider>
    );
};

export const useLayout = () => useContext(LayoutContext);
