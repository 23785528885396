export interface DashboardResponseModel<T> {
    hasError: boolean;
    message: string;
    data: T | null;
}

export function getDefaultErrorResponse<T>(): DashboardResponseModel<T> {
    return {
        data: null,
        hasError: true,
        message: 'Ocurrió un error en el consumo del recurso'
    };
}

export const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '5px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

export const focusedStyle = {
    borderColor: '#2196f3'
};

export const acceptStyle = {
    borderColor: '#257590'
};

export const rejectStyle = {
    borderColor: '#ff1744'
};
