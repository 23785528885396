import { Button, Chip, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { CitaModel } from 'types/administracion';
import MainCard from 'ui-component/cards/MainCard';
import { format } from 'date-fns';
import { getCitaResumenCompra } from 'api/calls';
import useAuth from 'hooks/useAuth';
import { useLayout } from 'contexts/LayoutContext';
import { Link, useParams } from 'react-router-dom';

export function getColorEstatusCita(idEstusCita: number): any {
    switch (idEstusCita) {
        case 1:
            return 'info';
        case 2:
            return 'warning';
        case 3:
            return 'info';
        case 4:
            return 'info';
        case 5:
            return 'success';
        case 6:
            return 'error';
        case 7:
            return 'error';

        default:
            return 'inherit';
    }
}

const CitaResumenCompra = () => {
    const { sessionId } = useParams();
    const { data } = useAuth();
    const { handleOpenBackdrop, handleCloseBackdrop } = useLayout();

    const [citaDetalle, setCitaDetalle] = useState<CitaModel | null>(null);

    const handleClickGetResumenCompra = () => {
        handleOpenBackdrop();
        getCitaResumenCompra(data?.token as string, sessionId as string).then((response) => {
            handleCloseBackdrop();
            if (!response.hasError) {
                setCitaDetalle(response.data);
            }
        });
    };

    useEffect(() => {
        handleClickGetResumenCompra();
        //eslint-disable-next-line
    }, []);

    if (!citaDetalle) {
        return null;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h1">¡Gracias por agendar tu cita!</Typography>
            </Grid>
            <Grid item xs={12} md={8}>
                <MainCard title="Fecha y hora de la cita">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5">Número de cita: {citaDetalle.numeroOrdenCita}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h2">{format(new Date(citaDetalle.fechaInicio), 'dd/MM/yyyy')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h1">
                                {format(new Date(citaDetalle.fechaInicio), 'h:mm aaa')} -{' '}
                                {format(new Date(citaDetalle.fechaFin), 'h:mm aaa')}
                            </Typography>
                        </Grid>
                    </Grid>
                </MainCard>
            </Grid>
            <Grid item xs={12} md={4}>
                <MainCard title="Datos de pago">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Chip label={citaDetalle.descripcionEstatusCita} color={getColorEstatusCita(citaDetalle.idEstatusCita)} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">Total de la cita:</Typography>
                            <Typography variant="h4">${citaDetalle.totalPagoCita}</Typography>
                            <Typography variant="body1">Pago por {citaDetalle.descripcionTipoPago}</Typography>
                        </Grid>
                    </Grid>
                </MainCard>
            </Grid>
            <Grid item xs={12} md={8}>
                <MainCard title="Dirección de la cita">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="caption">Hospital:</Typography>
                            <Typography>{citaDetalle.nombreCentroMedico}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">Dirección:</Typography>
                            <Typography>{citaDetalle.direccionCentroMedico}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">Doctor:</Typography>
                            <Typography>{citaDetalle.nombresDoctor}</Typography>
                            <Typography variant="subtitle2">{citaDetalle.nombreEspecialidad}</Typography>
                            <Typography variant="subtitle2">No. de célula {citaDetalle.cedulaProfesional}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">Paciente:</Typography>
                            <Typography>{citaDetalle.nombrePaciente}</Typography>
                            <Typography variant="subtitle2">{citaDetalle.correo}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="caption">Pregunta relacionada:</Typography>
                            <Typography>{citaDetalle.pregunta ? citaDetalle.pregunta : '(No hay pregunta relacionada)'}</Typography>
                        </Grid>
                    </Grid>
                </MainCard>
            </Grid>
            <Grid item xs={12} md={8}>
                <MainCard title="Dirección de la cita">
                    <Grid container spacing={2}>
                        <Grid item xs={12} dangerouslySetInnerHTML={{ __html: citaDetalle.ubicacionMaps }}></Grid>
                    </Grid>
                </MainCard>
            </Grid>

            <Grid item xs={12} md={8}>
                <Link to="/citas" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color="primary" fullWidth>
                        Ver todas las citas
                    </Button>
                </Link>
            </Grid>
        </Grid>
    );
};

export default CitaResumenCompra;
